<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">领取记录</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-tabs @tab-click="toSearch" v-model="search.coupon_type">
				<el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.name" :name="item.value">
				</el-tab-pane>
			</el-tabs>
			<div class="d-flex flex-wrap align-items-center">
				<div>领取时间：</div>
				<el-date-picker style="width: 400px;" class="m-1" size="small" @change="changeTime" v-model="time"
					type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<div class="d-flex align-items-center ml-3">
					<div>是否核销：</div>
					<el-button @click="handleClickIsUse(-1)" :type="search.is_use == -1 ?'primary':''" size="mini">全部
					</el-button>
					<el-button @click="handleClickIsUse(0)" class="ml-2" :type="search.is_use == 0 ?'primary':''"
						size="mini">否</el-button>
					<el-button @click="handleClickIsUse(1)" class="ml-2" :type="search.is_use == 1 ?'primary':''"
						size="mini">是</el-button>
				</div>
				<div class="d-flex align-items-center ml-3">
					<div>是否过期：</div>
					<el-button @click="handleClickIsExpire(-1)" :type="search.is_expire == -1 ?'primary':''"
						size="mini">全部
					</el-button>
					<el-button @click="handleClickIsExpire(0)" class="ml-2" :type="search.is_expire == 0 ?'primary':''"
						size="mini">否</el-button>
					<el-button @click="handleClickIsExpire(1)" class="ml-2" :type="search.is_expire == 1 ?'primary':''"
						size="mini">是</el-button>
				</div>
				<div class="d-flex align-items-center ml-3">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="100" prop="user_coupon_id" label="优惠券ID"></el-table-column>
				<el-table-column align="center" label="劵类型" width="90">
					<template slot-scope="scope">
						<el-tag size="mini" style="margin-right: 5px;">{{ scope.row.coupon_type.text }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="领取用户" width="190">
					<template slot-scope="scope">
						<div class="d-flex align-items-center">
							<viewer>
								<img class="mr-2" style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
							</viewer>
							<span style="">{{scope.row.user.nickName}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="赠送">
					<template slot-scope="scope">
						<span v-if="scope.row.is_give == 10">未赠送</span>
						<span v-else>赠送用户 ID：{{scope.row.current_user_id}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="劵名称/使用条件" width="180">
					<template slot-scope="scope">
						<span>{{scope.row.name}}</span><br>
						<span>{{scope.row.condition}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="核销码" prop="clerk_code"></el-table-column>
				<el-table-column align="center" label="状态">
					<template slot-scope="scope">
						<el-tag size="mini" v-if="scope.row.is_use == 0 && scope.row.is_expire == 0">未使用</el-tag>
						<el-tag size="mini" type="success" v-if="scope.row.is_use == 1">已使用</el-tag>
						<el-tag size="mini" type="warning" v-if="scope.row.is_expire == 1">已过期</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="有效期/领取时间" width="200">
					<template slot-scope="scope">
						{{scope.row.start_time}} / {{scope.row.end_time}}<br>{{scope.row.create_time}}
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="detail(scope.row.user_coupon_id)">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog title="详情" :visible.sync="couponVisible" append-to-body>
				<template v-if="coupon != null">
					<el-divider><span style="font-weight: 800;">基本信息</span></el-divider>
					<p class="d-flex align-items-center">领取用户：
						<viewer>
							<img class="mr-2" style="width: 45px;height: 45px;" :src="coupon.user.avatarUrl">
						</viewer>
						<span style="">{{coupon.user.nickName}}</span>
					</p>
					<p>劵类型：<el-tag size="small">{{coupon.coupon_type.text}}</el-tag>
					</p>
					<p>劵名称：{{coupon.name}}</p>
					<p>使用条件：{{coupon.condition}}</p>
					<p>使用须知：{{coupon.intro}}</p>
					<p>
						有效期：{{coupon.start_time}} - {{coupon.end_time}}
					</p>
					<p>
						可用时段（日期）： <el-tag size="small" v-if="coupon.hour.week.indexOf(10) > -1">周一</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(20) > -1">周二</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(30) > -1">周三</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(40) > -1">周四</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(50) > -1">周五</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(60) > -1">周六</el-tag>
						<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(70) > -1">周日</el-tag>
					</p>
					<p>
						可用时段（时间）： {{coupon.hour.time.start}} - {{coupon.hour.time.end}}
					</p>
					<p>
						状态： <el-tag size="mini" v-if="coupon.is_use == 0 && coupon.is_expire == 0">未使用</el-tag>
						<el-tag size="mini" type="success" v-if="coupon.is_use == 1">已使用</el-tag>
						<el-tag size="mini" type="warning" v-if="coupon.is_expire == 1">已过期</el-tag>
					</p>
					<p v-if="coupon.is_use == 1">
						使用时间：{{coupon.clerk_time}}
					</p>
					<p>
						是否赠送： <el-tag size="mini" v-if="coupon.is_give == 10">未赠送</el-tag>
						<el-tag size="mini" type="success" v-else>已赠送</el-tag>
					</p>
					<p  class="d-flex align-items-center" v-if="coupon.is_give == 20">
						赠送用户：<viewer>
							<img class="mr-2" style="width: 45px;height: 45px;" :src="coupon.currentUser.avatarUrl">
						</viewer>
						<span style="">{{coupon.currentUser.nickName}}</span>
					</p>
					<el-divider><span style="font-weight: 800;">活动规则</span></el-divider>
					<p>
						限制领取地区：<span v-if="coupon.rule.region_limit == 20">{{coupon.rule.province_name}}
							{{coupon.rule.city_name}} {{coupon.rule.region_name}}</span><span v-else>不限制</span>
					</p>
					<p>核销后赠送：<span v-if="coupon.rule.clerk_give == 20">开启</span><span v-else>关闭</span></p>
					<p>禁止赠送：<span v-if="coupon.rule.forbid_give == 20">开启</span><span v-else>关闭</span></p>
					<p>禁止分享：<span v-if="coupon.rule.forbid_share == 20">开启</span><span v-else>关闭</span></p>
					<p>分享更优惠（裂变卷）：<span v-if="coupon.rule.share_minus == 20">开启</span><span v-else>关闭</span></p>
					<p>用户信息收集：<span v-if="coupon.rule.is_user_info == 20">开启</span><span v-else>关闭</span></p>

					<el-divider><span style="font-weight: 800;">商家详情信息</span></el-divider>
					<p>商家名称：{{coupon.couponMerchant.merchant_name}}</p>
					<p class="d-flex align-items-center">LOGO：<viewer><img class="ml-3"
								style="width: 45px;height: 45px;" :src="coupon.couponMerchant.logo"></viewer>
					</p>
					<p>商家电话：{{coupon.couponMerchant.mobile}}</p>
					<p>微信号：{{coupon.couponMerchant.wechat}}</p>
					<p>商家地址：{{coupon.couponMerchant.address}}</p>
					<p>详细地址：{{coupon.couponMerchant.address_detail}}</p>
					<el-divider><span style="font-weight: 800;">图文详情</span></el-divider>
					<template v-for="(item,index) in coupon.couponImageText">
						<template v-if="item.type == 10">
							<p>{{item.guidance}}：{{item.content}}</p>
						</template>
						<template v-else>
							<p class="d-flex align-items-center">
								<viewer><img class="mr-2" style="width: 45px;height: 45px;" :src="item.image"></viewer>
								<span>{{item.content}}</span>
							</p>
						</template>
					</template>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				tabs: [{
						name: '全部',
						value: '0'
					},
					{
						name: '兑换券',
						value: '10'
					},
					{
						name: '满减券',
						value: '20'
					},
					{
						name: '折扣卷',
						value: '30'
					},
					{
						name: '代金券',
						value: '40'
					},
					{
						name: '裂变卷',
						value: '50'
					}
				],
				search: {
					coupon_type: '0',
					s: 'store/coupon/record',
					coupon_id: this.$route.params.coupon_id,
					user_id: this.$route.params.user_id,
					keyword: '',
					page: 1,
					is_expire: -1,
					is_use: -1,
					start_time: null,
					end_time: null,
				},
				time: [],
				couponVisible: false,
				coupon: null,
				user_coupon_id: null,
			}
		},
		watch: {
			$route() {
				this.search.coupon_id = this.$route.params.coupon_id
				this.getList()
			},
		},
		created() {
			this.getList();
		},
		methods: {
			detail(user_coupon_id) {
				this.user_coupon_id = user_coupon_id;
				this.couponVisible = true;
				this.getCouponDetail();
			},
			handleClickIsUse(is_use) {
				this.search.is_use = is_use;
				this.toSearch()
			},
			handleClickIsExpire(is_expire) {
				this.search.is_expire = is_expire;
				this.toSearch()
			},
			handleClick() {
				this.toSearch()
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			changeTime() {
				if (this.time) {
					this.search.start_time = this.time[0];
					this.search.end_time = this.time[1];
				} else {
					this.search.start_time = null;
					this.search.end_time = null;
				}
				this.toSearch();
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getCouponDetail() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/coupon/userCoupon',
						user_coupon_id: this.user_coupon_id
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.coupon = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>

</style>
